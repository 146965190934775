export const HOLIDAY_FONT = {
  HEADING: "heading",
  SUBTITLE: "subtitle",
  EYEBROW: "eyebrow",
  NONE: "none"
};

export const EDITORIAL_FONT = {
  YES: "yes",
  NO: "no"
};
