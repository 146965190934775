import { BannerPrice } from "@vp/swan";
import { IQuantityPrice, QtyFromPrice } from "@vp/vp-tokenized-fragment";
import React, { useCallback, useEffect, useState } from "react";
import { getPrice } from "../../clients";
import { TENANT } from "../../constants";
import { IBannerPriceProps } from "../../types";
import {
  constructPricingInfo,
  getPricingContext,
  getPricingContextEncodedString,
} from "../../utils";

const BannerPriceDisplay: React.FunctionComponent<IBannerPriceProps> = ({
  price,
  locale,
  hideVatMessageForPrice,
  hideShippingMessageInVatMessageForPrice,
}) => {
  const [pricingProps, setPricingProps] = useState<IQuantityPrice | null>(null);

  const getBannerPrice = useCallback(async () => {
    const pricingData = await getPrice({
      tenant: TENANT,
      locale: locale,
      mpvIds: price?.mpvId,
      pricingContext: getPricingContextEncodedString(),
    });

    if (pricingData) {
      const pricingContext = getPricingContext();
      const pricingInfo = constructPricingInfo({
        price: pricingData[price?.mpvId],
        vatInclusivity: !!pricingContext.vatInclusive,
        locale: locale,
        hideVatMessageForPrice: hideVatMessageForPrice,
        hideShippingMessageInVatMessageForPrice: hideShippingMessageInVatMessageForPrice,
      });

      setPricingProps(pricingInfo);
    }
  }, []);

  useEffect(() => {
    getBannerPrice();
  }, []);

  return (
    <BannerPrice data-testid='banner-price'>
      {!!pricingProps && (
        <QtyFromPrice tenant={TENANT} pricingInfo={pricingProps} placeHolderText='' />
      )}
    </BannerPrice>
  );
};

export { BannerPriceDisplay };
