import { CoreProps, Link } from "@vp/swan";
import React from "react";
import { IImageLink, ITrackingProps, ImageProps } from "../../types";
import { getImageProps, getLinkIdentifier, getTargetForLink, getTextProps } from "../../utils";
import { Image } from "../Image";

export type IBannerLogo = {
  bannerLogo: ImageProps;
  openInNewTab?: boolean;
  isDoubleBanner?: boolean;
  imageOverrideProps?: Omit<
    CoreProps<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>>,
    "alt"
  >;
  trackingHandler?: (event: React.MouseEvent, props: ITrackingProps) => void;
};

export const BannerLogo: React.FC<IBannerLogo> = ({
  bannerLogo,
  openInNewTab,
  isDoubleBanner,
  imageOverrideProps,
  trackingHandler,
}) => {
  const { imageSrc, imageLink, ariaLabel, altOverride } = bannerLogo;
  const handleTracking = (event: React.MouseEvent, imageLink: IImageLink) => {
    if (trackingHandler) {
      const { href, actionId, trackingId, linkType } = imageLink;

      const linkIdentifier = getLinkIdentifier(linkType, actionId, trackingId);
      trackingHandler(event, {
        destinationUrl: href,
        linkIdentifier: linkIdentifier as string,
        ctaPosition: 0,
        openInNewTab,
      });
    }
  };

  const imageProps = getImageProps(imageSrc, altOverride);
  return bannerLogo?.imageLink?.href ? (
    <Link
      href={imageLink?.href}
      onClick={(event) => handleTracking(event, imageLink as IImageLink)}
      data-testid='banner-logo-with-link'
      target={openInNewTab ? "_blank" : getTargetForLink(imageLink?.href)}
      aria-label={getTextProps(ariaLabel ?? "")}
    >
      <Image
        imageSrc={imageProps?.src}
        imageOverrideProps={imageOverrideProps}
        altOverride={imageProps?.altTextOverride ?? ""}
        isBannerLogo={true}
        isFluidImage={false}
        isDoubleBanner={isDoubleBanner}
      />
    </Link>
  ) : (
    <Image
      imageSrc={imageProps?.src}
      imageOverrideProps={imageOverrideProps}
      altOverride={imageProps?.altTextOverride ?? ""}
      isBannerLogo={true}
      isFluidImage={false}
      testId='banner-logo-without-link'
      isDoubleBanner={isDoubleBanner}
    />
  );
};
