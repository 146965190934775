import { RefObject, useEffect } from "react";

export const handlePlayPauseVisibility = (
  videoRef: RefObject<HTMLVideoElement>,
  videoSrc: { video: { src: string; breakpoint: number }[] } | undefined,
  setShowPlayPause: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    const updatePlayPauseVisibility = () => {
      if (videoSrc?.video?.length) {
        const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
        if (videoRef?.current) {
          const duration = videoRef.current.duration || 0;
          setShowPlayPause(duration > 5 && !isSmallScreen);
        } else {
          setShowPlayPause(!isSmallScreen);
        }
      }
    };

    const handleLoadedMetadata = () => {
      updatePlayPauseVisibility();
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    }
    window.addEventListener("resize", updatePlayPauseVisibility);

    updatePlayPauseVisibility();

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      }
      window.removeEventListener("resize", updatePlayPauseVisibility);
    };
  }, [videoRef, videoSrc, setShowPlayPause]);
};
