import { Button, Icon } from "@vp/swan";
import React, { useState } from "react";

interface PlayPauseButtonProps {
  videoRef: React.RefObject<HTMLVideoElement>;
}

const PlayPauseButton: React.FC<PlayPauseButtonProps> = ({ videoRef }) => {
  const [isPlaying, setIsPlaying] = useState(true);

  // change button icon from play to pause based on videoRef current playing status
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Button buttonShape='round' onClick={handlePlayPause}>
      {isPlaying ? (
        <Icon size='20p' iconType='pause' alt='pause' />
      ) : (
        <Icon size='20p' color='standard' iconType='play' alt='play' />
      )}
    </Button>
  );
};

export default PlayPauseButton;
