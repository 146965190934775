import {
  DefaultBannerWrapperProps,
  DefaultContentfulBannerWrapperProps,
  DefaultContentfulTextContentProps,
  ImageProps,
  TextProps,
} from "../types";
import { getImageProps, getProcessedProps } from "./dataProcessing";

// Note that shouldProcess is used internally for this library to avoid processing twice
export const validateBannerProps = (
  props: {
    firstBannerProps: Partial<DefaultContentfulBannerWrapperProps>;
    secondBannerProps?: Partial<DefaultContentfulBannerWrapperProps>;
  },
  shouldProcess = true,
) => {
  const { firstBannerProps, secondBannerProps } = props;
  const processedFirstBannerProps = shouldProcess
    ? getProcessedProps(firstBannerProps as DefaultContentfulBannerWrapperProps)
    : (firstBannerProps as DefaultBannerWrapperProps);
  const messages: string[] = [];
  let isValid = true;
  const { layout: firstBannerLayout, bannerImage = [], textContent } = processedFirstBannerProps;
  const {
    multiColumnOffer = false,
    withLeftHandNavigation = false,
    twoImages = false,
  } = firstBannerLayout ?? {};
  const { imageSrc, videoSrc } = bannerImage[0] || {};
  const { imageSrc: secondaryImageSrc = [], videoSrc: secondaryVideoSrc = [] } =
    bannerImage[1] || {};

  const primaryImageProps = getImageProps(imageSrc);
  const secondaryImageProps = getImageProps(secondaryImageSrc);

  const checkForZeroLength = (text?: string | string[] | TextProps | TextProps[]) => {
    if (!text) return true;

    if (Array.isArray(text)) {
      if (
        text.length === 0 ||
        (typeof text[0] === "string" && text[0].length === 0) ||
        (typeof text[0] === "object" && (!text[0]?.value || text[0]?.value?.length === 0))
      ) {
        return true;
      }
    } else if (typeof text === "string" && text.length === 0) {
      return true;
    } else if (typeof text === "object" && (!text?.value || text?.value?.length === 0)) {
      return true;
    }
    return false;
  };
  // Returns true if content is valid
  const checkForTextContent = (textObj: DefaultContentfulTextContentProps) => {
    if (
      !textObj ||
      (checkForZeroLength(textObj?.title) &&
        checkForZeroLength(textObj?.subtitle) &&
        checkForZeroLength(textObj?.discount))
    ) {
      return false;
    }
    return true;
  };

  if (secondBannerProps) {
    const processedSecondBannerProps = shouldProcess
      ? getProcessedProps(secondBannerProps as DefaultContentfulBannerWrapperProps)
      : secondBannerProps;
    const {
      layout: secondBannerLayout,
      bannerImage: secondBannerImage = [],
      textContent: secondBannerTextContent = {},
    } = processedSecondBannerProps;
    const { imageSrc: secondBannerImageSrc, videoSrc: secondBannerVideoSrc } =
      (secondBannerImage as ImageProps[])[0] || {};
    const secondBannerImageProps = getImageProps(secondBannerImageSrc);

    if (
      firstBannerLayout?.variant !== "full-width-image" ||
      secondBannerLayout?.variant !== "full-width-image"
    ) {
      messages.push("Both the banners should be of variant full-width-image for Double Banner");
      isValid = false;
    }
    if (firstBannerLayout?.multiColumnOffer || secondBannerLayout?.multiColumnOffer) {
      messages.push("Multi Column Layout is not supported for Double Banner");
      isValid = false;
    }
    if (firstBannerLayout?.twoImages || secondBannerLayout?.twoImages) {
      messages.push("Two Images Layout is not supported for Double Banner");
      isValid = false;
    }
    if (firstBannerLayout?.withLeftHandNavigation || secondBannerLayout?.withLeftHandNavigation) {
      messages.push("Left Navigation is not supported for Double Banner");
      isValid = false;
    }
    if (
      (!secondBannerImageSrc && !secondBannerVideoSrc) ||
      (!imageSrc && !videoSrc) ||
      (!primaryImageProps?.src?.[0]?.src && !videoSrc) ||
      (!secondBannerImageProps?.src?.[0]?.src && !secondBannerVideoSrc)
    ) {
      messages.push("One Image or video in each banner is required for Double Banner");
      isValid = false;
    }
    if (!checkForTextContent(secondBannerTextContent) || !checkForTextContent(textContent)) {
      messages.push("Title, subtitle or display in each banner is required for Double Banner");
      isValid = false;
    }
  } else {
    if (!checkForTextContent(textContent)) {
      messages.push("Title, subtitle or display is required");
      isValid = false;
    }
    if (!videoSrc && (!primaryImageProps?.src?.length || !primaryImageProps?.src?.[0]?.src)) {
      messages.push("Atleast one image or video is required");
      isValid = false;
    }
    if (multiColumnOffer) {
      if (firstBannerLayout?.variant !== "full-width-image") {
        messages.push("Banner Variant should be full-width-image for Multi Column Offer");
        isValid = false;
      }
      if (firstBannerLayout?.textBoxHorizontalAlign !== "center") {
        messages.push("Banner should have textBoxHorizontalAlign as center for Multi Column Offer");
        isValid = false;
      }
    } else if (withLeftHandNavigation) {
      if (firstBannerLayout?.variant !== "card") {
        messages.push("Variant should be card for Banner with Left Hand Navigation");
        isValid = false;
      }
      if (firstBannerLayout?.textBoxHorizontalAlign !== "left") {
        messages.push("textBoxHorizontalAlign should be left for Banner with Left Hand Navigation");
        isValid = false;
      }
    } else if (twoImages) {
      if (
        (!secondaryImageProps?.src?.length && !secondaryVideoSrc) ||
        (!secondaryImageProps?.src?.[0]?.src && !secondaryVideoSrc)
      ) {
        messages.push("Second Image or video is required for Two Images Layout");
        isValid = false;
      }
      if (firstBannerLayout?.variant !== "card") {
        messages.push("Variant should be card for Two Images Layout");
        isValid = false;
      }
    }
  }

  return {
    messages: messages,
    isValid,
  };
};

export const getMaxBreakpointSource = (srcSet: string | null): string | undefined => {
  if (!srcSet) return undefined;
  const srcArray = srcSet.split(", ").map((src) => src.trim());
  return srcArray[srcArray.length - 1].split(" ")[0];
};
