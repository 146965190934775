import { Link, ScreenClassProvider } from "@vp/swan";
import React from "react";
import { I18nProvider } from "../../localization/LanguageContext";
import { ContentfulBannerWrapperProps, Locale } from "../../types";
import { getLinkIdentifier, getProcessedProps, getTextProps } from "../../utils";
import { DefaultBannerWrapper } from "./DefaultBannerWrapper";
import { DoubleBannerWrapper } from "./DoubleBannerWrapper";
import { LeftNavBannerWrapper } from "./LeftNavBannerWrapper";
import { MultipleOffersBannerWrapper } from "./MultipleOffersBannerWrapper";

const BannerWrapper: React.FunctionComponent<ContentfulBannerWrapperProps> = (props) => {
  const {
    bannerProps,
    extraProps = {},
    leftNav,
    trackingHandler,
    locale,
    gmpvFlowComponent,
  } = props;
  const [firstBannerProps, secondBannerProps] = bannerProps;
  const { layout, bannerLink = {} } = firstBannerProps;
  const {
    multiColumnOffer = false,
    withLeftHandNavigation = false,
    openInNewTab = false,
  } = layout || {};

  const handleTracking = (event: React.MouseEvent) => {
    if (trackingHandler) {
      const { href, actionId, trackingId, linkType } = bannerLink;

      const linkIdentifier = getLinkIdentifier(linkType, actionId, trackingId);
      trackingHandler(event, {
        destinationUrl: href,
        linkIdentifier: linkIdentifier as string,
        ctaPosition: 0,
        openInNewTab,
      });
    }
  };

  const getWrapper = () => {
    if (multiColumnOffer) {
      return bannerLink?.href ? (
        <MultipleOffersBannerWrapper
          {...getProcessedProps(firstBannerProps)}
          extraProps={extraProps}
          id={props?.id}
          render={(props) => (
            <Link
              {...props}
              href={bannerLink?.href}
              className={props.className}
              data-testid='multi-offer-wrapper-with-link'
              onClick={handleTracking}
              target={openInNewTab ? "_blank" : "_self"}
              id={props?.id}
              aria-label={getTextProps(firstBannerProps?.textContent?.ariaLabel ?? "")}
            >
              {props.children}
            </Link>
          )}
          trackingHandler={trackingHandler}
          locale={locale}
          gmpvFlowComponent={gmpvFlowComponent}
        />
      ) : (
        <MultipleOffersBannerWrapper
          {...getProcessedProps(firstBannerProps)}
          extraProps={extraProps}
          trackingHandler={trackingHandler}
          locale={locale}
          id={props?.id}
          gmpvFlowComponent={gmpvFlowComponent}
        />
      );
    }

    if (withLeftHandNavigation || !!leftNav) {
      return bannerLink?.href ? (
        <LeftNavBannerWrapper
          {...getProcessedProps(firstBannerProps)}
          extraProps={extraProps}
          leftNav={leftNav}
          render={(props) => (
            <Link
              {...props}
              href={bannerLink?.href}
              className={props.className}
              data-testid='leftnav-banner-wrapper-with-link'
              onClick={handleTracking}
              target={openInNewTab ? "_blank" : "_self"}
              id={props?.id}
              aria-label={getTextProps(firstBannerProps?.textContent?.ariaLabel ?? "")}
            >
              {props.children}
            </Link>
          )}
          trackingHandler={trackingHandler}
          locale={locale}
          id={props?.id}
          gmpvFlowComponent={gmpvFlowComponent}
        />
      ) : (
        <LeftNavBannerWrapper
          {...getProcessedProps(firstBannerProps)}
          extraProps={extraProps}
          leftNav={leftNav}
          trackingHandler={trackingHandler}
          locale={locale}
          id={props?.id}
          gmpvFlowComponent={gmpvFlowComponent}
        />
      );
    }

    return bannerLink?.href ? (
      <DefaultBannerWrapper
        {...getProcessedProps(firstBannerProps)}
        extraProps={extraProps}
        id={props?.id}
        render={(props) => (
          <Link
            {...props}
            href={bannerLink?.href}
            className={props.className}
            data-testid='banner-with-link'
            onClick={handleTracking}
            id={props?.id}
            target={openInNewTab ? "_blank" : "_self"}
            aria-label={getTextProps(firstBannerProps?.textContent?.ariaLabel ?? "")}
          >
            {props.children}
          </Link>
        )}
        trackingHandler={trackingHandler}
        locale={locale}
        gmpvFlowComponent={gmpvFlowComponent}
      />
    ) : (
      <DefaultBannerWrapper
        {...getProcessedProps(firstBannerProps)}
        extraProps={extraProps}
        trackingHandler={trackingHandler}
        locale={locale}
        id={props?.id}
        gmpvFlowComponent={gmpvFlowComponent}
      />
    );
  };
  return (
    <ScreenClassProvider>
      <I18nProvider defaultLocale={locale as Locale}>
        {!secondBannerProps ? (
          getWrapper()
        ) : (
          <DoubleBannerWrapper
            firstBannerProps={getProcessedProps(firstBannerProps)}
            secondBannerProps={getProcessedProps(secondBannerProps)}
            hideBannerBelowMedium={firstBannerProps?.hideBannerBelowMedium}
            extraProps={extraProps}
            trackingHandler={trackingHandler}
            locale={locale}
            id={props?.id}
          />
        )}
      </I18nProvider>
    </ScreenClassProvider>
  );
};

export { BannerWrapper };
