export const PLTC_MARKETING_COLORS = {
  atlantic: "#16618c",
  "black-900": "#000000",
  caspian: "#204949",
  celtic: "#046240",
  "dark-grey": "#5c5c5c",
  emerald: "#0a806b",
  graphite: "#313131",
  holiday: "#9a0202",
  "holiday-beige-1": "#ede3cf",
  "holiday-green-1": "#318750",
  "holiday-green-2": "#06462E",
  "holiday-red-1": "#A5030B",
  "holiday-red-2": "#A5030B",
  kingfisher: "#017eb4",
  "medium-green": "#318643",
  "medium-grey": "#dfdfdf",
  opal: "#c0e6df",
  platinum: "#efefef",
  rio: "#99d3f3",
  rum: "#c94b2f",
  talavera: "#2b3b55",
  "warm-white": "#f6f6ec",
  white: "#ffffff",
};

export const PLTC_MARKETING_COLOR_FORMAT_KEBAB_CASE = {
  atlantic: "atlantic",
  black900: "black-900",
  caspian: "caspian",
  celtic: "celtic",
  darkGrey: "dark-grey",
  emerald: "emerald",
  graphite: "graphite",
  holiday: "holiday",
  holidayBeige1: "holiday-beige-1",
  holidayGreen1: "holiday-green-1",
  holidayGreen2: "holiday-green-2",
  holidayRed1: "holiday-red-1",
  holidayRed2: "holiday-red-2",
  kingfisher: "kingfisher",
  mediumGreen: "medium-green",
  mediumGrey: "medium-grey",
  opal: "opal",
  platinum: "platinum",
  rio: "rio",
  rum: "rum",
  talavera: "talavera",
  warmWhite: "warm-white",
  white: "white",
};
