import { IPricingContext } from "../types";

type DocumentWithContext = Document & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pcxtV3: any;
};

export const getPricingContextEncodedString = (): string => {
  const doc = typeof document !== "undefined" ? document : undefined;
  if (doc) {
    return (doc as DocumentWithContext)?.pcxtV3?.getEncodedContextString() ?? "";
  }

  return "";
};

export const getPricingContext = (): IPricingContext => {
  const doc = typeof document !== "undefined" ? document : undefined;
  if (doc) {
    return (doc as DocumentWithContext)?.pcxtV3?.getPricingContext() ?? "";
  }

  return {};
};
