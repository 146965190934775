import { DoubleBanner, Link } from "@vp/swan";
import React, { useEffect, useState } from "react";
import { DoubleBannerWrapperProps, IBannerLink } from "../../types/banner";
import { getLinkIdentifier, getTextProps, validateBannerProps } from "../../utils";
import { DefaultBannerWrapper } from "./DefaultBannerWrapper";

const DoubleBannerWrapper: React.FunctionComponent<DoubleBannerWrapperProps> = ({
  firstBannerProps,
  secondBannerProps,
  hideBannerBelowMedium = "none",
  extraProps,
  trackingHandler,
  locale,
  id,
}) => {
  const [error, setError] = useState(false);
  const { bannerLink: firstBannerLink, layout: firstBannerLayout } = firstBannerProps;
  const { bannerLink: secondBannerLink, layout: secondBannerLayout } = secondBannerProps;
  const { openInNewTab: firstBannerOpenInNewTab = false } = firstBannerLayout;
  const { openInNewTab: secondBannerOpenInNewTab = false } = secondBannerLayout;
  const idProps = { ...(id ? { id: id } : {}) };

  const handleTracking = (
    event: React.MouseEvent,
    bannerLink: IBannerLink,
    openInNewTab: boolean,
    bannerId?: number,
  ) => {
    if (trackingHandler) {
      const { href, actionId, trackingId, linkType } = bannerLink;
      const linkIdentifier = getLinkIdentifier(linkType, actionId, trackingId);
      trackingHandler(event, {
        destinationUrl: href,
        linkIdentifier: linkIdentifier as string,
        ctaPosition: bannerId ?? 0,
        openInNewTab,
      });
    }
  };

  useEffect(() => {
    const validationMessage = validateBannerProps(
      {
        firstBannerProps,
        secondBannerProps,
      },
      false,
    );
    if (!validationMessage?.isValid) {
      console.error("Banner validation error: " + validationMessage?.messages?.join(". "));
      setError(true);
    }
  }, [firstBannerProps, secondBannerProps]);

  return error ? null : (
    <DoubleBanner
      data-testid='double-banner-wrapper'
      hideFirstBannerBelowMedium={hideBannerBelowMedium === "left"}
      hideSecondBannerBelowMedium={hideBannerBelowMedium === "right"}
      {...idProps}
    >
      {firstBannerLink?.href ? (
        <DefaultBannerWrapper
          {...firstBannerProps}
          extraProps={extraProps}
          render={(props) => (
            <Link
              {...props}
              href={firstBannerLink?.href}
              className={props.className}
              onClick={(event) =>
                handleTracking(event, firstBannerLink, firstBannerOpenInNewTab, 1)
              }
              data-testid='double-banner-first-with-link'
              target={firstBannerOpenInNewTab ? "_blank" : "_self"}
              aria-label={getTextProps(firstBannerProps?.textContent?.ariaLabel ?? "")}
            >
              {props.children}
            </Link>
          )}
          trackingHandler={trackingHandler}
          locale={locale}
          isDoubleBanner
          isFirstBanner
        />
      ) : (
        <DefaultBannerWrapper
          {...firstBannerProps}
          extraProps={extraProps}
          trackingHandler={trackingHandler}
          locale={locale}
          isDoubleBanner
          isFirstBanner
        />
      )}
      {secondBannerLink?.href ? (
        <DefaultBannerWrapper
          {...secondBannerProps}
          extraProps={extraProps}
          bannerImage={secondBannerProps?.bannerImage ?? []}
          render={(props) => (
            <Link
              {...props}
              href={secondBannerLink?.href}
              className={props.className}
              onClick={(event) =>
                handleTracking(event, secondBannerLink, secondBannerOpenInNewTab, 2)
              }
              data-testid='double-banner-second-with-link'
              target={secondBannerOpenInNewTab ? "_blank" : "_self"}
              aria-label={getTextProps(secondBannerProps?.textContent?.ariaLabel ?? "")}
            >
              {props.children}
            </Link>
          )}
          trackingHandler={trackingHandler}
          locale={locale}
          isDoubleBanner
          firstBannerCTAButtons={firstBannerProps?.ctaButton?.length}
        />
      ) : (
        <DefaultBannerWrapper
          {...secondBannerProps}
          extraProps={extraProps}
          bannerImage={secondBannerProps?.bannerImage ?? []}
          trackingHandler={trackingHandler}
          locale={locale}
          isDoubleBanner
          firstBannerCTAButtons={firstBannerProps?.ctaButton?.length}
        />
      )}
    </DoubleBanner>
  );
};

export { DoubleBannerWrapper };
