import qs from "qs";
import { TENANT } from "../constants";
import { EstimatedPrice } from "../types";
import { DEV_API_ENDPOINT, PROD_API_ENDPOINT, REQUESTOR } from "../utils";
export type PricingResponse = Record<string, EstimatedPrice>;

export interface PricingRequestParam {
  tenant: string;
  locale: string;
  mpvIds: string;
  pricingContext?: string;
}

const BFF_BASE_URL = process.env.NODE_ENV === "production" ? PROD_API_ENDPOINT : DEV_API_ENDPOINT;

export const getPrice = async (
  props: PricingRequestParam,
): Promise<PricingResponse | undefined> => {
  const { tenant = TENANT, locale, mpvIds, pricingContext } = props;

  const queryString = qs.stringify(
    { requestor: REQUESTOR, mpvIds, pricingContext },
    { arrayFormat: "repeat" },
  );

  const url = `${BFF_BASE_URL}/v1/pricing/${tenant}/${locale}?${queryString}`;

  let response: PricingResponse | undefined = undefined;

  try {
    const data = await fetch(url);
    response = await data.json();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(`Banner Wrapper: Failed to get pricing from BFF, ${url} => ${error}`);
  }

  return response;
};
