import { Button, Link } from "@vp/swan";
import React from "react";
import { CTAButtonProps } from "../../types/banner";
import { getLinkIdentifier, getTextProps } from "../../utils";

const CTAButton: React.FunctionComponent<CTAButtonProps> = ({
  skin = "primary",
  label = "",
  href = {},
  type,
  trackingHandler,
  ctaPosition,
  openInNewTab = false,
  isDoubleBanner,
  firstBannerCTAButtons = 0,
  gmpvUploadButton = {},
  gmpvFlowComponent = <></>,
}) => {
  if (gmpvUploadButton?.mpvId) {
    return gmpvFlowComponent;
  }
  const { actionId, trackingId, href: path = "", linkType } = href;
  const linkIdentifier = getLinkIdentifier(linkType, actionId, trackingId);;
  const trackingPosition = ctaPosition ?? 0;
  const handleTracking = (event: React.MouseEvent) => {
    if (trackingHandler) {
      trackingHandler(event, {
        destinationUrl: path,
        linkIdentifier: linkIdentifier as string,
        ctaPosition: isDoubleBanner
          ? `2:${firstBannerCTAButtons + trackingPosition}`
          : `${trackingPosition}`,
        openInNewTab,
      });
    }
  };
  const processedLabel = getTextProps(label);

  switch (type) {
    case "link":
      return processedLabel ? (
        <Link href={path} onClick={handleTracking} target={openInNewTab ? "_blank" : "_self"}>
          {processedLabel}
        </Link>
      ) : (
        <></>
      );
    case "ctaLink":
      return processedLabel ? (
        <Link
          skin='cta'
          href={path}
          onClick={handleTracking}
          target={openInNewTab ? "_blank" : "_self"}
        >
          {processedLabel}
        </Link>
      ) : (
        <></>
      );
    default:
      return processedLabel ? (
        <Button
          skin={skin}
          render={(p) => (
            <Link href={path} className={p.className} onClick={handleTracking}>
              {processedLabel}
            </Link>
          )}
        />
      ) : (
        <></>
      );
  }
};

export { CTAButton };
