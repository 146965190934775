import { BannerEyebrow, FlexBox, Typography, useScreenClass } from "@vp/swan";
import React from "react";
import { HOLIDAY_FONT } from "../../constants";
import { IEyebrow } from "../../types";
import { getImageProps, getTextProps } from "../../utils";
import { Image } from "../Image";

export const Eyebrow: React.FC<IEyebrow> = ({
  eyebrowText = "",
  icon,
  holidayFont,
  imageOverrideProps,
  textBoxHorizontalAlign,
  variant,
  twoImages,
}) => {
  const screenClass = useScreenClass();
  const isExtraSmall = screenClass === "xs";
  const isMediumOrSmall = screenClass === "md" || screenClass === "sm";
  const alignment =
    variant === "card"
      ? twoImages
        ? "center"
        : "flex-start"
      : textBoxHorizontalAlign === "center"
        ? "center"
        : "flex-start";
  const justifyContent =
    twoImages && isMediumOrSmall ? "flex-start" : isExtraSmall ? "center" : alignment;

  if (icon?.imageSrc && !eyebrowText) {
    const imageProps = getImageProps(icon.imageSrc, icon?.altOverride);
    return (
      <BannerEyebrow
        as='div'
        fontFamily={holidayFont === HOLIDAY_FONT.EYEBROW ? "secondary" : "primary"}
        data-testid='eyebrow-with-icon'
        textAlign='center'
      >
        <Image
          imageSrc={imageProps.src}
          altOverride={imageProps?.altTextOverride ?? ""}
          isFluidImage={false}
          imageOverrideProps={imageOverrideProps}
        />
      </BannerEyebrow>
    );
  } else if (icon?.imageSrc) {
    const imageProps = getImageProps(icon.imageSrc, icon?.altOverride);
    return (
      <BannerEyebrow
        as='div'
        fontFamily={holidayFont === HOLIDAY_FONT.EYEBROW ? "secondary" : "primary"}
        data-testid='eyebrow-with-icon'
      >
        <FlexBox alignItems='center' justifyContent={justifyContent}>
          <Image
            imageSrc={imageProps.src}
            altOverride={imageProps?.altTextOverride ?? ""}
            isFluidImage={false}
            imageOverrideProps={imageOverrideProps}
          />
          {!!eyebrowText && <Typography ml='1'>{getTextProps(eyebrowText)}</Typography>}
        </FlexBox>
      </BannerEyebrow>
    );
  }

  return (
    <BannerEyebrow
      fontFamily={holidayFont === HOLIDAY_FONT.EYEBROW ? "secondary" : "primary"}
      data-testid='eyebrow-without-icon'
    >
      {getTextProps(eyebrowText)}
    </BannerEyebrow>
  );
};
