import { CoreProps } from "@vp/swan";
import React, { RefObject } from "react";
import { ImageSrcProps } from "../../types";
import { getTextProps } from "../../utils";
import { Image } from "../Image";

interface VideoProps {
  videoSrc: { video: { src: string; breakpoint: number; poster: string }[] };
  videoRef: RefObject<HTMLVideoElement>;
  fallbackImage: { src?: ImageSrcProps[]; altTextOverride?: string };
  imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
  ariaLabelForVideo?: string;
}

const Video: React.FC<VideoProps> = ({
  videoSrc,
  videoRef,
  fallbackImage,
  imageOverrideProps,
  ariaLabelForVideo,
}) => {
  const sortedVideos = [...videoSrc.video].sort((a, b) => b.breakpoint - a.breakpoint);
  const defaultPoster = sortedVideos[0]?.poster;

  return (
    <>
      <Image
        imageSrc={fallbackImage?.src || []}
        altOverride={fallbackImage?.altTextOverride}
        imageOverrideProps={imageOverrideProps}
        className='banner-video-image swan-hidden-sm swan-hidden-md swan-hidden-lg swan-hidden-xl'
      />

      <video
        loop
        playsInline
        muted
        ref={videoRef}
        data-testid='banner-video'
        style={{ width: "100%", height: "auto" }}
        poster={defaultPoster}
        className='banner-video swan-hidden-xs'
        aria-label={ariaLabelForVideo && getTextProps(ariaLabelForVideo)}
      >
        {sortedVideos.map((video) => (
          <source
            key={video.breakpoint}
            src={video.src}
            media={`(min-width: ${video.breakpoint}px)`}
            type='video/mp4'
          />
        ))}
      </video>
    </>
  );
};

export default Video;
