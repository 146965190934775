import { CoreProps, FluidImage, ResponsiveImage, useScreenClass } from "@vp/swan";
import React, { useState } from "react";
import { ImageSrcProps } from "../../types";
import { getProcessedImageSourceSet } from "../../utils";
import { getMaxBreakpointSource } from "../../utils/validateBanner";

export type IImage = {
  imageSrc: ImageSrcProps[];
  onError?: () => void;
  altOverride?: string;
  imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
  isFluidImage?: boolean;
  testId?: string;
  isBannerLogo?: boolean;
  isDoubleBanner?: boolean;
  className?: string;
};

export const Image: React.FC<IImage> = ({
  imageSrc,
  altOverride,
  imageOverrideProps,
  isFluidImage = true,
  testId,
  isBannerLogo = false,
  isDoubleBanner = false,
  onError,
  className,
}) => {
  const processedImageSrc = getProcessedImageSourceSet(imageSrc);
  const [validSrcSet, setValidSrcSet] = useState<string | null>(processedImageSrc);
  const screenClass = useScreenClass();
  const isExtraSmall = screenClass === "xs";

  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const currentSrc = event.currentTarget.src;
    setValidSrcSet((prevSrcSet) => {
      const updatedSrcSet =
        prevSrcSet
          ?.split(", ")
          .filter((src) => !src.startsWith(currentSrc))
          .join(", ") || null;
      if (!updatedSrcSet) {
        console.error("All images in the source set have failed to load.");
      }

      return updatedSrcSet;
    });

    if (onError) {
      onError();
    }
  };

  if (!validSrcSet) return null;

  const primarySrc = getMaxBreakpointSource(validSrcSet);

  if (isFluidImage && validSrcSet)
    return (
      <FluidImage
        key={validSrcSet}
        src={primarySrc}
        onError={handleError}
        srcSet={validSrcSet}
        alt={altOverride || ""}
        data-testid={testId}
        {...imageOverrideProps}
        className={className}
      />
    );

  if (isBannerLogo && validSrcSet)
    return (
      <ResponsiveImage
        key={validSrcSet}
        src={primarySrc}
        onError={handleError}
        srcSet={validSrcSet}
        alt={altOverride || ""}
        data-testid={testId}
        {...imageOverrideProps}
        mb={"3"}
        style={{
          height: isDoubleBanner || isExtraSmall ? "60px" : "80px",
        }}
      />
    );

  return (
    <img
      key={validSrcSet}
      src={primarySrc}
      srcSet={validSrcSet}
      onError={handleError}
      alt={altOverride || ""}
      style={{ display: "inline-block" }}
      data-testid={testId}
      {...imageOverrideProps}
      className={className}
    />
  );
};
