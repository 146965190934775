import { IQuantityPrice } from "@vp/vp-js-token-engine";
import { EstimatedPrice } from "../types";

export type IConstructPricingInfoProps = {
  price: EstimatedPrice;
  vatInclusivity: boolean;
  locale: string;
  hideVatMessageForPrice?: boolean;
  hideShippingMessageInVatMessageForPrice?: boolean;
};

export const constructPricingInfo = ({
  price,
  vatInclusivity,
  locale,
  hideVatMessageForPrice,
  hideShippingMessageInVatMessageForPrice,
}: IConstructPricingInfoProps): IQuantityPrice => {
  const selectedQuantity = parseInt(Object.keys(price.estimatedPrices)[0]);

  const estimatedPrice = price?.estimatedPrices[selectedQuantity];

  return {
    minimumFractionDigits: price?.fractionDigits,
    currency: price?.currency,
    vatInc: vatInclusivity,
    culture: locale?.toLowerCase(),
    quantity: selectedQuantity,
    discountPrice: vatInclusivity
      ? estimatedPrice?.totalDiscountedPrice?.taxed
      : estimatedPrice?.totalDiscountedPrice?.untaxed,
    listPrice: vatInclusivity
      ? estimatedPrice?.totalListPrice?.taxed
      : estimatedPrice?.totalListPrice?.untaxed,
    hideVatMessage: !!hideVatMessageForPrice,
    hideShippingMessageInVatMessage: !!hideShippingMessageInVatMessageForPrice,
  };
};
